import { axiosAdminService, cacheService, systemService } from './index'
import {
    Program,
    Client,
    ClientSummaryDetails,
    Partner,
    Contact,
    ClientSummary,
    ContactSummary,
    AdminInvestor,
    ProgramSummary,
    InvestorSummary,
    ClientClosingConditions,
    AdminConfig,
    AdminTrustees,
    LenderProfile,
    Reference,
    AdminServiceProvider,
    ClientRegCheckOptions,
    SystemIntegrationClient,
    EcoaNotice,
    PagedCollection,
    LenderWarehouseBankDetails, 
    WarehouseBank, 
    WarehouseBankSummary,
    FeatureFlag,
    FeatureFlagStatus,
    DocumentCategory,
    ClientPackageType,
    PreparedBy,
    DocPrepAttorney
} from '@/common/models'
import { PackageType, ClientDeliveryMethod } from '@/propel/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { MultiSelectHelper } from '@/common/utilities/helper'
import { config } from '@/config'
import { InvestorLookup } from '../models/investor-lookup'

class AdminDataService {
    //TODO: this endpoint need to be built into the admin data api and used to filter and populate the programs  drop down for a loan
    async getPrograms(params?: URLSearchParams): Promise<Program[]> {
        const response = await axiosAdminService.get(`/programs`, { params })
        return response.data
    }

    async getProgramSummaries(params?: URLSearchParams): Promise<PagedCollection<ProgramSummary>>  {
        const response = await axiosAdminService.get(`/programs`, { params })
        return response.data
    }

    async GetFilteredPrograms(params?: URLSearchParams): Promise<Program[]> {
        const response = await axiosAdminService.get(`/programs/filtered`, { params })
        return response.data
    }
    
    async getProgramsByClientAndInvestor(clientId = '', investorId = ''): Promise<Program[]> {
        const response = await axiosAdminService.get(`/programs/GetByClientandInvestor?applicableClient=${clientId}&applicableInvestor=${investorId}`)
        return response.data
    }

    async getProgram(id: string): Promise<Program>  {
        const response = await axiosAdminService.get(`/programs/${id}`)
        return response.data
    }

    async postProgram(program: Program): Promise<Program> {
        const response = await axiosAdminService.post(`/programs`, program)
        return response.data
    }

    async deleteProgram(id: string) {
        const response = await axiosAdminService.delete(`/programs/${id}`)
        return response.data
    }

    async getClientInvestorSummaries(clientCode: string, params?: URLSearchParams): Promise<PagedCollection<InvestorSummary>> {
        const response = await axiosAdminService.get(`/clients/${clientCode}/investors`, { params })
        return response.data
    }

    async getClientInvestorSummaryLookups(clientCode: string, params?: URLSearchParams): Promise<InvestorLookup[]> {
        const response = await axiosAdminService.get(`/clients/${clientCode}/investors/lookup`, { params })
        return response.data
    }
    
    async getInvestorSummaries(params?: URLSearchParams): Promise<PagedCollection<InvestorSummary>>  {
        const response = await axiosAdminService.get(`/investors`, { params })
        return response.data
    }

    async getInvestorLookups(): Promise<Reference[]>  {
        const response = await axiosAdminService.get(`/investors/lookup`)
        return response.data
    }

    async getInvestor(id: string): Promise<AdminInvestor>  {
        const response = await axiosAdminService.get(`/investors/${id}`)
        return response.data
    }

    async postInvestor(investor: AdminInvestor): Promise<AdminInvestor> {
        const response = await axiosAdminService.post(`/investors`, investor)
        return response.data
    }

    async deleteInvestor(id: string) {
        const response = await axiosAdminService.delete(`/investors/${id}`)
        return response.data
    }

    async getClients(params?: URLSearchParams): Promise<PagedCollection<ClientSummary>> {
        const response = await axiosAdminService.get(`/clients`, { params })
        return response.data
    }

    async getClient(code: string): Promise<Client> {
        const cacheId = `client_${code}`
        const response = await cacheService.getItem(cacheId, () => axiosAdminService.get(`/clients/${code}`))
        return response.data
    }

    async getClientClosingConditions(code: string): Promise<ClientClosingConditions> {
        const response = await axiosAdminService.get(`/clients/${code}/closing-conditions`)
        return response.data
    }

    async getClientDeliveryMethods(code: string, propertyState: string, packageType: string): Promise<ClientDeliveryMethod> {
        const params = new URLSearchParams()
        params.append('propertyState', propertyState)
        params.append('packageType', packageType)
        const response = await axiosAdminService.get(`/clients/${code}/delivery-methods`, {params})
        return response.data
    }

    async postClient(client: Client): Promise<Client> {
        cacheService.removeItem(`client_${client.code}`)
        const response = await axiosAdminService.post(`/clients`, client)
        return response.data
    }

    async defaultClient(): Promise<Client> {
        const response = await axiosAdminService.get('/clients/default')
        return response.data
    }

    async deleteClient(code: string) {
        cacheService.removeItem(`client_${code}`)
        const response = await axiosAdminService.delete(`/clients/${code}`)
        return response.data
    }

    async getClientsLookup(params?: URLSearchParams): Promise<MultiSelectItem[]> {
        const response = await axiosAdminService.get(`/clients/lookup`, { params: this.addRegCheckEnabled(params) })
        return MultiSelectHelper.removeValuePrefixFromText(MultiSelectHelper.formatToMultiSelect(response.data))
    }

    async getPartnersLookup(params?: URLSearchParams): Promise<MultiSelectItem[]> {
        const response = await axiosAdminService.get('/partners', { params: this.addRegCheckEnabled(params) }) 
        return response.data.map( p => { return {text: p.name, value: p.code } as MultiSelectItem })
    }

    private addRegCheckEnabled(params?: URLSearchParams) {
        if (config.app.regCheckOnly) {
            params ??= new URLSearchParams()
            params.append('regCheckEnabled', 'true')
        }

        return params
    }

    async getClientPackageTypes(clientCode: string, documentMode: string | null = null): Promise<ClientPackageType[]> {
        let url = `/clients/${clientCode}/package-types`
        if (documentMode) url += `?documentMode=${documentMode}`
        
        const response = await axiosAdminService.get(url)
        return response.data
    }

    async getClientContacts(clientCode: string, types: string[] | null = null): Promise<Contact[]> {
        let url = `clients/${clientCode}/contacts/lookup`
        if (types != null && types.length > 0) {
            url += `?contactTypes=${types.join('&contactTypes=')}`
        }
        const response  = await axiosAdminService.get(url)
        return response.data
    }

    async getPagedClientContacts(clientCode: string, params?: URLSearchParams): Promise<PagedCollection<ContactSummary>> {
        const response = await axiosAdminService.get(`/clients/${clientCode}/contacts/library`, { params })
        return response.data
    }

    async getClientDocusignTransactionOptions(clientCode: string, packageType: string, emailAddress: string): Promise<ClientSummaryDetails> {
        const params = new URLSearchParams({ packageType: packageType, emailAddress: emailAddress })
        const response = await axiosAdminService.get(`/Clients/${clientCode}/delivery/docusign/transaction-options`, { params }) 
        return response.data
    }

    async patchClientConfiguration(code: string, adminConfig: AdminConfig): Promise<Client> {
        const response = await axiosAdminService.patch(`/clients/${code}/configuration`,  adminConfig )
        return response.data
    }

    async getContact(id: string): Promise<Contact> {
        const response = await axiosAdminService.get(`/contacts/${id}`)
        return response.data
    }

    async postContact(contact: Contact): Promise<Contact> {
        const response = await axiosAdminService.post(`/contacts`, contact)
        return response.data
    }

    async deleteContact(id: string) {
        const response = await axiosAdminService.delete(`/contacts/${id}`)
        return response.data
    }

    async getPackageTypes(): Promise<PackageType[]> {
        const response = await axiosAdminService.get('/package-types')
        return response.data
    }

    async deletePackageType(code: string) {
        const response = await axiosAdminService.delete(`/package-types/${code}`)
        return response.data
    }

    async postPackageType(packageType: PackageType): Promise<PackageType> {
        const response = await axiosAdminService.post('/package-types', packageType)
        return response.data
    }

    async getPartners(params?: URLSearchParams): Promise<Partner[]> {
        const response = await axiosAdminService.get('/partners', { params })
        return response.data
    }

    async getPartner(code: string): Promise<Partner> {
        const response = await axiosAdminService.get(`/partners/${code}`)
        return response.data
    }

    async postPartner(partner: Partner): Promise<Partner> {
        const response = await axiosAdminService.post('/partners', partner)
        return response.data
    }

    async deletePartner(code: string) {
        const response = await axiosAdminService.delete(`/partners/${code}`)
        return response.data
    }
    async getClientTrusteeDefaults(code: string): Promise<AdminTrustees[]> {
        const response = await axiosAdminService.get(`/clients/${code}/trustees/system-defaults`)
        return response.data
    }

    async getClientPreparedByDefaults(code: string): Promise<PreparedBy[]> {
        const response = await axiosAdminService.get(`/clients/${code}/prepared-by/system-defaults`)
        return response.data
    }

    async getClientDocPrepAttorneyDefaults(code: string): Promise<DocPrepAttorney[]> {
        const response = await axiosAdminService.get(`/clients/${code}/doc-prep-attorney/system-defaults`)
        return response.data
    }

    async getEcoaNotices(searchTerm?: string): Promise<EcoaNotice[]>{
        const response = await axiosAdminService.get('/ecoa-notices', {
            params: {
                searchTerm
            }
        });
        return response.data;
    }
    async postEcoaNotice(ecoaNotice: EcoaNotice){
        const response = await axiosAdminService.post('/ecoa-notices', ecoaNotice);
        return response.data;
    }
    async deleteEcoaNotice(id: string){
        await axiosAdminService.delete(`/ecoa-notices/${id}`)
    }

    async createAppKey(client: string, overwrite: boolean): Promise<string> {
        const response = await axiosAdminService.post(`/clients/${client}/app-key?overwrite=${overwrite}`)
        return response.data
    }
    async getAppKey(client: string): Promise<string> {
        const response =  await axiosAdminService.get(`/clients/${client}/app-key`)
        return response.data
    }

    async deleteAppKey(client: string): Promise<boolean> {
        const response = await axiosAdminService.delete(`/clients/${client}/app-key`)
        return response.status === 200;
    }

    async getLenderProfile(clientCode: string, lenderProfileId: string): Promise<LenderProfile|null>{
        if(!clientCode || !lenderProfileId) return null;
        const response = await axiosAdminService.get(`/clients/${clientCode}/lender-profile/${lenderProfileId}`);
        return response.data;
    }

    async getInvestorsForLoan(clientCode: string): Promise<AdminInvestor[]>{
        if(!clientCode) return [];
        const response = await axiosAdminService.get(`/investors/loan/${clientCode}`);
        return response.data;
    }

    async getServiceProviders(clientCode: string, params?: URLSearchParams){
        const response = await axiosAdminService.get(`/clients/${clientCode}/service-providers`, {params})
        return response.data;
    }

    async getServiceProvider(clientCode: string, id: string): Promise<AdminServiceProvider>{
        const response = await axiosAdminService.get(`/clients/${clientCode}/service-providers/${id}`);
        return response.data;
    }

    async postServiceProvider(serviceProvider: AdminServiceProvider) {
        await axiosAdminService.post(`/clients/${serviceProvider.clientCode}/service-providers`, serviceProvider);
    }

    async deleteServiceProvider(clientCode: string, id: string){
        await axiosAdminService.delete(`/clients/${clientCode}/service-providers/${id}`);
    }

    async getAllServiceProvidersForClient(clientCode: string, state?: string): Promise<AdminServiceProvider[]>{
        const response = await axiosAdminService.get(`/clients/${clientCode}/service-providers/lookup`, {params: {state}});
        return response.data
    }

    async patchClientRegCheck(clientCode: string, regCheckOptions: ClientRegCheckOptions){
        const response = await axiosAdminService.patch(`/clients/${clientCode}/regcheck`, regCheckOptions)
        return response.data
    }

    async getSystemIntegrationClients(): Promise<SystemIntegrationClient[]> {
        const response = await axiosAdminService.get('integrations/clients')
        return response.data
    }

    async getSystemIntegrationClient(clientId: string): Promise<SystemIntegrationClient> {
        const response = await axiosAdminService.get(`integrations/clients/${clientId}`)
        return response.data
    }

    async postNewSystemIntegrationClient(client: SystemIntegrationClient) {
        const response = await axiosAdminService.post(`integrations/clients/${client.clientId}`, client)
        return response.data
    }

    async putSystemIntegrationClient(client: SystemIntegrationClient) {
        const response = await axiosAdminService.put(`integrations/clients/${client.clientId}`, client)
        return response.data
    }

    async deleteSystemIntegrationClient(clientId: string) {
        const response = await axiosAdminService.delete(`integrations/clients/${clientId}`)
        return response.data
    }

    async getWarehouseBankSummaries(params: URLSearchParams): Promise<PagedCollection<WarehouseBankSummary>> {
        const response = await axiosAdminService.get(`warehouse-banks`, {params})
        return response.data
    }

    async getWarehouseBankLookup(): Promise<Reference[]> {
        const response = await axiosAdminService.get(`warehouse-banks/lookup`)
        return response.data
    }

    async getWarehouseBank(id: string): Promise<WarehouseBank>  {
        const response = await axiosAdminService.get(`/warehouse-banks/${id}`)
        return response.data
    }

    async postWarehouseBank(warehouseBank: WarehouseBank): Promise<WarehouseBank> {
        const response = await axiosAdminService.post(`/warehouse-banks`, warehouseBank)
        return response.data
    } 

    async deleteWarehouseBank(id: string): Promise<WarehouseBank>  {
        const response = await axiosAdminService.delete(`/warehouse-banks/${id}`)
        return response.data
    }

   async getClientWarehouseBankDetails(clientCode: string): Promise<LenderWarehouseBankDetails[]> {
       const response = await axiosAdminService.get(`/clients/${clientCode}/warehouse-bank-details`)
       return response.data
   }

   async postClientFeePayees(clientCode: string, loanFeePayees: string[]): Promise<string[]>{
        const response = await axiosAdminService.post(`/clients/${clientCode}/fee-payees`, loanFeePayees)
        return response.data
   }

   async postClientCustomDocumentCategories(clientCode: string, customCategoryMappingText: string): Promise<DocumentCategory[]> {
        const requestData = { "mappingText" : customCategoryMappingText }
        const response = await axiosAdminService.post(`/clients/${clientCode}/document-categories`, requestData)
        return response.data
   }

    async getFeatureFlagStatus(flagKey: string, clientCode: string): Promise<FeatureFlagStatus> {
        const cacheId = `feature_flag_${clientCode}_${flagKey}`
        const response = await cacheService.getItem(cacheId, () => axiosAdminService.get(`/feature-flags/${flagKey}`, {params: {clientCode}}))
        return response.data
    }

    async getAllFeatureFlagStatuses(clientCode: string): Promise<FeatureFlagStatus[]> {
        const response = await axiosAdminService.get(`/feature-flags`, {params: {clientCode}})
        return response.data
    }
    async postFeatureFlags(flags: Map<string, boolean>, partnerCode: null | string = null, clientCode: null | string = null, username: null | string = null): Promise<FeatureFlag[]> {
        systemService.resetFlagCache() // Reset cache in case a flag was updated not to apply to a client, partner, or lender anymore
        const response = await axiosAdminService.post('/feature-flags', { flags, partnerCode, clientCode, username })
        return response.data
    }

   clearClientCache(code: string) {
        const cacheId = `client_${code}`
        cacheService.removeItem(cacheId)
   }
}

export const adminDataService = new AdminDataService()
