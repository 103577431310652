<template>
    <div class="content-block-list" :class="size">
        <draggable
            v-model="localListItems"
            ghost-class="ghost"
            :scroll-sensitivity="200"
            v-if="localListItems"
            handle=".drag"
            v-click-outside="closeEditModeFromOutsideClick"
            :class="[dragging ? 'dragging' : '', 'content-block-items']"
            @start="dragging = true"
            @end="dragging = false"
            @change="onDragChange"
        >
            <content-block
                v-for="(item, index) in localListItems"
                :key="itemKey ? item[itemKey] : index"
                :id="`${id}ContentBlockItem${index}`"
                :edit-mode="editMode"
                v-on="$listeners"
                :item="item"
                :index="index"
                :managed-edit="itemIsInEditMode(item)"
                @openEdit="editItem"
                @closeEdit="closeEditMode(item)"
                @cancelEdit="cancelEditMode"
                @mousedown="onBlockMouseDown"
                @mouseup="onBlockMouseUp"
                :itemCanSave="itemCanSave"
            >
                <template v-for="(_, slot) of slots" v-slot:[slot]="scope">
                    <slot
                        :name="slot"
                        v-bind="scope"
                        v-bind:item="item"
                        v-bind:index="index"
                        v-bind:originalValue="originalValue"
                        v-bind:deleteItem="deleteItem"
                    />
                </template>
                <template v-slot:secondaryActions v-if="canDrag(item) || $slots.secondaryActions">
                    <custom-icon icon="Drag" v-if="canDrag(item)" />
                    <slot name="secondaryActions" v-bind:item="item" />
                </template>
                <template v-slot:editButtons>
                    <slot name="editButtons" v-bind:item="item" v-bind:index="index" />
                    <custom-button
                        icon="Delete"
                        label="Delete"
                        variant="danger"
                        v-if="isDeletable(item) && !readOnly && !alwaysOpen"
                        @click="deleteItem(item)"
                        title="Delete this item"
                    />
                    <custom-button icon="Copy" label="Copy" variant="primary" v-if="copyable && !readOnly" @click="copyItem(item, $event)" :menuItems="copyItemDataSource" title="Copy this item" />
                </template>
                <template v-slot:primaryActions>
                    <slot name="primaryActions" v-bind:item="item" v-bind:index="index" />
                    <custom-icon icon="Copy" v-if="copyable && !readOnly" title="Copy Item" @click="copyItem(item, $event)" @selection="copyItem(item, $event)" :items="copyItemDataSource" />
                    <custom-icon icon="Delete" v-if="isDeletable(item) && !readOnly" title="Delete Item" @click.stop="deleteItem(item)" />
                </template>
            </content-block>
        </draggable>
        <slot name="summary" />
        <custom-button
            v-if="showAddButton"
            :class="alwaysOpen && 'add-button'"
            icon="Add"
            :label="addLabelText"
            :id="`addListItem-${id}`"
            @click="addItem"
            :menuItems="addItemDataSource"
            :clear-search-on-select="clearSearchOnSelect"
            :disabled="disableAdd || readOnly"
        />
        <div v-if="limitMessage">
            <label>{{ limitMessage }}</label>
        </div>
        <delete-confirmation-modal v-model="showDeleteModal" @ok="deleteItemInternal(itemDeleting, indexDeleting)">
            <template v-slot:shortMessage><slot name="deleteModal" v-bind:item="itemDeleting"></slot></template>
            <template v-slot:longMessage><slot name="deleteModalLongMessage"></slot></template>
        </delete-confirmation-modal>
    </div>
</template>

<script setup lang="ts">
import { MultiSelectItem } from './multi-select-item'
import { cloneDeep } from 'lodash'
import { ItemMovedEventArgs, ItemsChangedEventArgs, ContentBlockEditModeEnum } from '@/common/models'
import { PropType, computed, ref, defineProps, defineEmits, useSlots, useListeners, defineExpose } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"update:listItems", items: any[])
    (e:"dragged", moveEvent: ItemMovedEventArgs)
    (e:"itemCopied", item: any)
    (e:"saveItemCopied", item: any)
    (e:"editListItem", item: any, index: number)
    (e:"itemEditing", item: any, index: number)
    (e:"itemDeleted", item: any, index: number)
    (e:"itemUpdated", item: any) 
    (e:"openItemsChanged", itemChangedEvent: ItemsChangedEventArgs)
}>()

const props = defineProps({
    id: { type: String, required: true },
    listItems: { type: Array as PropType<Array<any>>, required: true },
    addItemDataSource: { type: Array as PropType<Array<MultiSelectItem>> },
    copyItemDataSource: { type: Array as PropType<Array<MultiSelectItem>> },
    size: { type: String, default: 'l' },
    copyable: { type: Boolean, default: false },
    sortField: { type: String, default: '' },
    addLabelText: { type: String, default: "Add Item" },
    closeText: { type: String, default: "Close" },
    itemKey: { type: String },
    hideAddButton: { type: Boolean, default: false },
    disableAddButton: { type: Boolean, default: false },
    clearSearchOnSelect: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    editMode: { type: Number as PropType<ContentBlockEditModeEnum>, default: ContentBlockEditModeEnum.AutoClose },
    openEditIndex: { type:  Array as PropType<Array<number>>, default: null },
    itemCanSave: { type: Function as PropType<(item: any) => boolean>, default: () => {return true} },
    onAdd: { type: Function as PropType<(item: any) => any>, default: () => {return {}} },
    onAddAsync: { type: Function as PropType<(item: any) => any | undefined>},
    draggable: { type: [Boolean, Function] as PropType<(item: any) => boolean>, default: false},
    deletable: { type: [Boolean, Function] as PropType<(item: any) => boolean>, default: false},
    onCopy: { type: Function as PropType<(item: any, menuItem?: any) => void>, default: () => {}},
    onAddAtIndex: { type: Function as PropType<((item: any) => { index: number; item: any }) | undefined>},
    limitMessage: { type: String }
})

const slots = useSlots()
const listeners = useListeners() // obsolete in vue 3

const editingItems = ref([] as any[])
const originalValue = ref(null)
const itemDeleting = ref(null)
const blockTriggeredMouseDown = ref(false)
const addingNewItem = ref(false)
const dragging = ref(false)
const showDeleteModal = ref(false)
const indexDeleting = ref(-1)

//Expose cancelEditMode ref to parent to use when clicking close/cancel in an edit modal
defineExpose({cancelEditMode})
//#endregion

//#region COMPUTED
const alwaysOpen = computed(() => props.editMode === ContentBlockEditModeEnum.AlwaysOpen)
const showSaveButton = computed(() => props.editMode == ContentBlockEditModeEnum.ManualClose || props.editMode === ContentBlockEditModeEnum.SaveCancel )
const showAddButton = computed(() => !disabled.value && !props.hideAddButton )
const disableAdd = computed(() => props.disableAddButton || (props.editMode == ContentBlockEditModeEnum.SaveCancel && editingItems.value.length > 0))
const disabled = computed(() => props.editMode === ContentBlockEditModeEnum.ReadOnly)

const localListItems = computed({
    get() {
        return props.listItems
    },
    set(items: any[]) {
        emit('update:listItems', items)
    }
})
//#endregion

//#region INITIALIZE
initialize()

function initialize() {
    openEditItems()
}
//#endregion

function onDragChange(event: any) {
    const moveEvent = event.moved as ItemMovedEventArgs
    emit('dragged', moveEvent)
}

function hasDeleteSlot(): boolean {
    return !!slots.deleteModal
}

function hasCustomEdit(): boolean {
    return !!listeners.editListItem
}

function canDrag(item): boolean {
    return !disabled.value && !props.readOnly && (isDraggable(item) || !!slots.secondaryIcons)
}

function isDraggable(item) {
    return (typeof props.draggable === "boolean") ? props.draggable : props.draggable(item);
}

function isDeletable(item) {
    return (typeof props.deletable === "boolean") ? props.deletable : props.deletable(item);
}

// This may need updating when trying to open edit for multiple items. There is a lot going on in editItem(), for now this works with AutoClose and opening only one item
function openEditItems() {
    if (props.openEditIndex && localListItems.value.length != 0) {
        props.openEditIndex.forEach((x) => {
            var index = localListItems.value.indexOf(localListItems.value[x])

            if (index != -1) editItem(localListItems.value[x], index)
        })
    }
}

// TODO: The functionality here for cloning and canceling changes
// should be moved to content-block
function editItem(item, index, isNew = false) {
    if (disabled.value) 
        return

    if (editingItems.value.length > 0) {
        // If ManualClose/SaveCancel is the mode being used and we are currently editing an item, emit saveItemCopied to allow the parent to handle saving the copied item
        if (showSaveButton.value) {
            emit('saveItemCopied', item)
            return
        }
        else
            editingItems.value.pop()
    }

    editingItems.value.push(item)

    if (!isNew) 
        originalValue.value = cloneDeep(item)

    if (hasCustomEdit()) 
        emit('editListItem', item, index)
    else 
        emit('itemEditing', item, index)
}

async function addItem(item: MultiSelectItem) {
    addingNewItem.value = true
    let index = localListItems.value?.length
    let newItem: any

    if (props.onAddAtIndex) {
        const result = props.onAddAtIndex(item?.value)
        index = result.index
        newItem = result.item
    } else if (props.onAddAsync) {
        newItem = await props.onAddAsync(item?.value)
    } else {
        newItem = props.onAdd(item?.value)
    }

    localListItems.value.splice(index, 0, newItem)
    localListItems.value = [...localListItems.value]
    editItem(newItem, index, true)
}

function copyItem(item?: any, menuItem?: any) {
    addingNewItem.value = true
    const newItem = cloneDeep(item)
    props.onCopy(newItem, menuItem)
    localListItems.value.push(newItem)
    const index = localListItems.value?.length - 1
    editItem(newItem, index, true)
    emit('itemCopied', item)
}

function deleteItem(item?: any) {
    const index = localListItems.value.indexOf(item)
    if (index >= 0) {
        itemDeleting.value = item
        indexDeleting.value = index
        if (hasDeleteSlot()) {
            showDeleteModal.value = true
        } else {
            deleteItemInternal(item, index)
        }
    }
    localListItems.value = [...localListItems.value]
}

function deleteItemInternal(item: any, index: number) {
    localListItems.value.splice(index, 1)
    itemDeleting.value = null
    indexDeleting.value = -1
    emit('itemDeleted', item, index)
    if (props.editMode !== ContentBlockEditModeEnum.AlwaysOpen) {
        closeEditMode(item, false)
    }
}

function itemIsInEditMode(item) {
    if (!slots.edit) return undefined
    return alwaysOpen.value || editingItems.value.indexOf(item) > -1
}

function closeEditMode(item?: any, emitUpdateEvent = true) {
    if (itemIsInEditMode(item)) {
        if (emitUpdateEvent)
            emitUpdate(item)

        const index = editingItems.value.indexOf(item)
        if (index > -1) editingItems.value.splice(index, 1)
        originalValue.value = null
        addingNewItem.value = false
        openItemsChanged(item)
    }
}

function openItemsChanged(item?: any) {
    emit('openItemsChanged', { item, openItemsCount: editingItems.value.length } as ItemsChangedEventArgs)
}

function cancelEditMode(item?: any) {
    const index = localListItems.value.indexOf(item)
    const editIndex = editingItems.value.indexOf(item)
    if (!originalValue.value) {
        localListItems.value.splice(index, 1)
    } else if (index > -1) {
        localListItems.value[index] = originalValue.value
    }
    if (editIndex > -1) {
        editingItems.value.splice(editIndex, 1)
    }
    originalValue.value = null
    addingNewItem.value = false
    openItemsChanged(item)
}

function emitUpdate(item: any) {
    emit('itemUpdated', item)
}

// onBlockMouseDown and onBlockMouseUp track whether user clicked inside an individual block and prevent
// closeEditModeFromOutsideClick from firing when a user clicks down inside a block (often to highlight text)
// and moves the mouse outside of the block before triggering onMouseUp
function onBlockMouseDown() {
    blockTriggeredMouseDown.value = true
}

function onBlockMouseUp() {
    blockTriggeredMouseDown.value = false
}

function closeEditModeFromOutsideClick() {
    if (blockTriggeredMouseDown.value) {
        blockTriggeredMouseDown.value = false
        return
    }
    if (props.editMode === ContentBlockEditModeEnum.AlwaysOpen) return
    if (!showSaveButton.value && !addingNewItem.value && editingItems.value.length) {
        const item = editingItems.value[0]
        if (item !== null && item !== undefined) {
            closeEditMode(item)
        }
    } else if (addingNewItem.value) {
        addingNewItem.value = false
    }
}
</script>

<style lang="scss" scoped>
$content-breakpoint-s: 500px;
$content-breakpoint-m: 1100px;

.content-block-list {
    margin: 0.25rem 0 1rem 0;

    // these s/m/l styles are also used in .label-value-list
    &.s {
        width: 25rem;

        @media only screen and (max-width: $content-breakpoint-s) {
            width: auto;
        }
    }

    &.m {
        width: 50rem;

        @media only screen and (max-width: $content-breakpoint-m) {
            width: auto;
        }
    }

    &.l {
        width: 100%;
    }

    .content-block-items {
        margin-bottom: 1rem;
    }

    .add-button {
        margin-top: 1rem;
    }

    .custom-icon.drag {
        font-size: 1.125rem;
        width: 0.5rem;
    }
}

.dragging {
    .content-block .content-area {
        &:hover {
            background: unset;
        }
        .custom-icon:hover {
            color: unset !important;
            transform: unset;
        }
    }
}
</style>