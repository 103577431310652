
<template>
    <div ref="table">
        <div class="filter-row" ref="filterRow" v-if="hasSearch || hasNavigation">
            <search-input v-if="hasSearch" @keyup="onSearchInput()" v-model="searchTerm" :warningMessage="warningMessage" />
            <template  v-for="(filter, index) in tableFilters">
                <input-select
                    v-if="!filter.hidden"
                    :key="`${filter.key}`"
                    :id="`${filter.key}-searchTable-filter-${index}`"
                    :stickyItems="filter.stickyItems"
                    :source="filter.source"
                    :showEmptyOption="filter.showEmptyOption"
                    :showSelectedItemsValueOnDisplay="showSelectedItemsValueOnDisplay"
                    :showDescriptionAfterSelect="showDescriptionAfterSelect"
                    :optionDisplayType="filter.optionDisplayType"
                    :searchable="true"
                    :useLocalSearch="true"
                    v-model="filter.value"
                    :multiple="filter.multiple"
                    :placeholder="filter.placeholder"
                    @change="filterChanged(filter.onChangeClearFilterKey)"
                />
            </template>
            <date-picker
                v-for="filter in dateTableFilters"
                :key="filter.key"
                :placeholder="`${filter.label}`"
                :id="`${filter.key}-dateFilter`"
                class="date-picker"
                v-model="filter.value"
                @input="filterChanged()"
            />
            <pagination
                v-if="metadata && metadata.pageNumber && metadata.pageSize && metadata.totalCount"
                :countOptions="[25, 100, 250]"
                :currentPage="metadata.pageNumber"
                :totalCount="metadata.totalCount"
                :pageSize="metadata.pageSize"
                @next="goToPage"
                @previous="goToPage"
                @first="goToPage"
                @last="goToPage"
                @page-changed="goToPage"
                @count-changed="onPageSize"
            ></pagination>
        </div>
        <b-table
            hover
            small
            :striped="striped"
            :no-local-sorting="serverSideSorting"
            no-border-collapse
            no-sort-reset
            @sort-changed="onSortChanged"
            :sort-by.sync="sortItemState.item"
            :sort-desc.sync="sortItemState.desc"
            :busy="loading"
            @row-clicked="onRowClicked"
            :fields="filteredFields"
            :items="!list ? [] : list"
            select-mode="single"
            :tbody-tr-class="rowClass"
            @row-hovered="onRowHovered" 
            @row-unhovered="onRowUnhovered"
            :class="{ 'has-action': hasAction, 'default-row-hover': primaryKey && !allowRowClick }"
        >
            <template v-slot:table-busy>
                <loading-overlay :show="true" text="Loading data" />
            </template>
            <template v-slot:head()="data">
                <div>
                    {{ data.label }}
                    <custom-icon v-if="data.field.key === sortItemState.item" :icon="sortItemState.desc ? 'SortDescending' : 'SortAscending'" />
                </div>
            </template>
            <template v-slot:cell()="data">
                <slot name="cell()" v-bind="data">
                    <span
                        v-if="!allowRowClick && data.field.key === primaryKey"
                        :class="{ split: formatSecondaryData }"
                        v-b-tooltip.html.hover="{ customClass: 'tooltip-pre' }"
                        :title="formatTitle(data.field.key, data.item)"
                    >
                        <span class="hyperlink" @click="onCellClicked(data.item)">{{ formatData(data.field.key, data.item) }} </span>
                        <span v-if="formatSecondaryData" class="subtitle">{{ formatSecondaryData(data.field.key, data.item) }}</span>
                    </span>
                    <span
                        v-else-if="data.field.formatHtml"
                        v-b-tooltip.html.hover="{ customClass: 'tooltip-pre' }"
                        :title="formatTitle(data.field.key, data.item)"
                        v-html="formatHtml(data.field.key, data.item)"
                    ></span>
                    <span
                        class="split"
                        v-else-if="formatSecondaryData(data.field.key, data.item)"
                        v-b-tooltip.html.hover="{ customClass: 'tooltip-pre' }"
                        :title="formatTitle(data.field.key, data.item)"
                        v-html="
                            `<span>${formatData(data.field.key, data.item)}</span>
                                    <span class='subtitle'>${formatSecondaryData(data.field.key, data.item)}</span>`
                        "
                    >
                    </span>
                    <span v-else v-b-tooltip.html.hover="{ customClass: 'tooltip-pre' }" :title="formatTitle(data.field.key, data.item)">{{ formatData(data.field.key, data.item) }}</span>
                </slot>
            </template>
            <template v-slot:cell(action)="data" v-if="hasAction">
                <slot name="cell(action)" v-bind="data">
                    <div class="action-items">
                        <custom-button icon="Copy" :label="copyLabel" @click="emit('copyItem', data.item)" v-if="emitCopy" :disabled="readOnly" />
                        <custom-button
                            icon="Delete"
                            :label="deleteLabel"
                            @click="emit('deleteItem', data.item)"
                            variant="danger"
                            v-if="emitDelete"
                            :disabled="readOnly"
                        />
                        <slot name="options" v-bind="data"></slot>
                    </div>
                </slot>
            </template>
        </b-table>
        <b-tooltip v-if="showRowTooltip" show :target="rowTooltipTarget" triggers="manual" >
            <div v-html="rowTooltip"></div>
        </b-tooltip>
    </div>
</template>

<script setup lang="ts">
import { TableHeaderFields, PagingMetadata, TableFilters, TableFilterValue, DateTableFilter, PagedCollection } from '@/common/models/meta'
import Pagination from '@/common/components/pagination.vue'
import { BvTableCtxObject } from 'bootstrap-vue'
import { Route } from 'vue-router'
import { debounce, isEmpty, isEqual } from 'lodash'
import { userPreferenceService } from '@/common/services'
import { localDateTimeFilter } from '@/common/filters'
import { computed, PropType, ref, watch, defineEmits, defineProps, onMounted, useSlots } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'routeChanged', payload: any): void
    (e: 'update:refreshList', value: boolean | undefined)
    (e: 'deleteItem', payload: any): void
    (e: 'copyItem', payload: any): void
    (e: 'filterChanged'): void // Needed to hide filters before the query is updated. Remove when this component can handle filters received from api call
    (e: 'rowHovered', payload: any)
}>()

const props = defineProps({
    tableFields: {
        type: Array as PropType<Array<TableHeaderFields>>,
        default() {
            return [] as TableHeaderFields[]
        }
    },
    hasSearch: { type: Boolean, default: true },
    hasNavigation: { type: Boolean, default: true },
    defaultSort: { type: String, required: true },
    gridId: { type: String, default: '' },
    primaryKey: { type: String, default: '' },
    emitCopy: { type: Boolean, default: false },
    copyLabel: { type: String, default: 'Copy' },
    emitDelete: { type: Boolean, default: false },
    deleteLabel: { type: String, default: 'Delete' },
    refreshList: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    serverSideSorting: { type: Boolean, default: true },
    showSelectedItemsValueOnDisplay: { type: Boolean, default: true },
    showDescriptionAfterSelect: { type: Boolean, default: false },
    tableFilters: {
        type: Array as PropType<Array<TableFilters>>,
        default() {
            return [] as TableFilters[]
        }
    },
    dateTableFilters: {
        type: Array as PropType<Array<DateTableFilter>>,
        default() {
            return [] as DateTableFilter[]
        }
    },
    offsetSearch: { type: Number, default: null },
    offsetRow: { type: Number, default: null },
    getList: { type: Function as PropType<(params?: URLSearchParams) => Promise<PagedCollection<any>>>, default: () => {} },
    dataKey: { type: Function as PropType<(item) => string>, default: () => {} },
    rowClicked: { type: Function as PropType<(itemSelected: any) => void>, default: () => {} },
    rowClass: { type: Function as PropType<(item: any) => string>, default: () => {} },
    rowTooltip: {type: String, default: ''}
})

const route = useRoute()
const slots = useSlots()
const router = useRouter()
const warningMessage = ref<string | null>(null)
const loading = ref(false)
const routeChanged = ref(0)
const metadata = ref<PagingMetadata | null>(null)
const list = ref([] as any[])
const searchTerm = ref('')
const queryDict = ref({} as any)
const filterRow = ref()
const table = ref()
const mostRecentParams = ref() 

const breakpointPriority = ref(0)
const fields = ref(props.tableFields)
const rowTooltipTarget = ref()
const rowHovered = ref(false)
//#endregion

//#region COMPUTED
const hasAction = computed<boolean>(() => props.emitCopy || props.emitDelete || slots.options !== undefined)
const striped = computed<boolean>(() => userPreferenceService.getAlternateGridRowColors())
const allowRowClick = computed<boolean>(() => userPreferenceService.isGridRowSelectionTypeAnywhere())

// column priorities are implemented using JS instead of CSS so that they are removed from
// markup, which allows our "td:nth-last-of-type(2)" selector to dim the last visible column
const filteredFields = computed(() => fields.value.filter((x) => breakpointPriority.value === 0 || !x.priority || x.priority <= breakpointPriority.value))

const sortItemState = computed(() => {
    const sort = metadata.value?.sort || [props.defaultSort]
    let sortItem = { item: sort[0], desc: false }

    if (sort[0].charAt(0) === '-') {
        sortItem = { item: sort[0].substring(1, sort[0].length), desc: true }
    }

    return sortItem
})

const timeToRefresh = computed({
    get: () => {
        return props.refreshList
    },
    set: (value: boolean) => {
        emit('update:refreshList', value)
    }
})
const showRowTooltip = computed<boolean>(() => rowHovered.value && !! props.rowTooltip)
//#endregion

//#region INITIALIZE
initialize()

onMounted(async () => {
    if (table.value) {
        const observer = new ResizeObserver(debounce((entries) => {
            setBreakpointPriority(entries[0].contentRect.width)
        }))
        observer.observe(table.value)
    }

    let offsetHeight = props.offsetRow ? props.offsetRow : table.value.offsetTop
    offsetHeight = filterRow.value ? offsetHeight : offsetHeight - 40
    const ths = table.value.querySelector('thead') as HTMLElement
    applyStickyStyling(ths, offsetHeight)

    if (filterRow.value) {
        const rowOffsetHeight = props.offsetSearch ? props.offsetSearch : table.value.offsetTop
        applyStickyStyling(filterRow.value, rowOffsetHeight - filterRow.value.offsetHeight)
    }

    updateQueryString()
    //if the route didn't change when building the query, explicitly load the data initially
    if (routeChanged.value < 1) {
        await handlePagedResponse(generatePagedParams(metadata.value))
    }
})

async function initialize() {
    if (hasAction.value) {
        // TODO: This seems to cause a delay when this column is added to the table
        // It seems to be a processing delay - something about the custom button is taking a little while to initialize when the table rerenders
        // It may be possible to use a simpler component used in places like this - something that has a smaller amount of html and/or reactivity overhead to allow for faster re-rendering
        fields.value.push({ key: 'action', label: '', class: 'col-action' })
    }

    await updateFilters(route)
}
//#endregion

//#region WATCH
watch(
    () => route,
    async (route: Route) => updateFilters(route),
    { immediate: true, deep: true }
)
watch(
    () => props.refreshList,
    async (value: boolean) => {
        if (value) {
            timeToRefresh.value = false
            await handlePagedResponse(generatePagedParams(metadata.value))
        }
    }
)
//#endregion

function onRowHovered(item: any, _: number, event: any) {
    rowHovered.value = true
    emit('rowHovered', item)
    rowTooltipTarget.value = event.target
}

function onRowUnhovered() {
    rowHovered.value = false
    rowTooltipTarget.value = null
}

function setBreakpointPriority(wrapperWidth) {
    if (wrapperWidth < 600) {
        breakpointPriority.value = 1
    } else if (wrapperWidth < 850) {
        breakpointPriority.value = 2
    } else if (wrapperWidth < 1100) {
        breakpointPriority.value = 3
    } else if (wrapperWidth < 1250) {
        breakpointPriority.value = 4
    } else if (wrapperWidth < 1400) {
        breakpointPriority.value = 5
    } else {
        breakpointPriority.value = 0
    }
}

async function updateFilters(route: Route) {
    const query = route.query
    const search = query.searchTerm
    if (search) {
        searchTerm.value = search.toString()
    } else {
        searchTerm.value = ''
    }

    metadata.value = metadata.value || ({} as PagingMetadata)
    metadata.value.filters = []
    metadata.value.searchTerm = searchTerm.value
    metadata.value.pageNumber = metadata.value.pageNumber || 1

    if (query.pageNumber && !isNaN(Number(query.pageNumber.toString()))) {
        metadata.value.pageNumber = parseInt(query.pageNumber.toString())
    }
    //handles grid preferences
    if (props.gridId) {
        metadata.value = metadata.value || ({} as PagingMetadata)
        const pageSize = userPreferenceService.getGridPageSize(props.gridId)
        metadata.value.pageSize = pageSize ? pageSize : metadata.value.pageSize || 25

        const savedSortBySettings = userPreferenceService.getGridSortBy(props.gridId)
        
        if (savedSortBySettings) {
            const sortCtx = JSON.parse(savedSortBySettings) as BvTableCtxObject
            metadata.value.sort = [`${sortCtx?.sortDesc ? '-' : ''}${sortCtx.sortBy}`]
        }
    }

    //looks at keys in route.query
    for (const key in query) {
        const filter = props.tableFilters.find((x) => x.key === key)
        if (filter) {
            const filterValues = query[key].toString().split(',')
            filter.value = filter.multiple ? filterValues : filterValues[0]
            filterValues.forEach((filterValue) => {
                const tf: TableFilterValue = {
                    key: key,
                    value: filterValue
                }
                metadata.value?.filters.push(tf)
            })
        } else {
            const dateFilter = props.dateTableFilters.find((x) => x.key === key)
            if (dateFilter) {
                const dateQueryValue = query[key].toString()
                const df: TableFilterValue = {
                    key: key,
                    value: dateQueryValue
                }
                metadata.value?.filters.push(df)
            }
        }
    }

    if (isEmpty(metadata.value.filters) && props.tableFilters.some((i) => i.value)) {
        props.tableFilters
            .filter((i) => i.value)
            .forEach((i) => {
                const tableFilterVal = Array.isArray(i.value) ? [...i.value] : [i.value]
                tableFilterVal.forEach((j) => {
                    metadata.value?.filters.push({ key: i.key, value: j })
                })
            })
    }
    if (routeChanged.value > 0) {
        await handlePagedResponse(generatePagedParams(metadata.value))
        routeChanged.value -= 1
    }
}

function updateQueryString() {
    if (searchTerm.value) {
        queryDict.value['searchTerm'] = searchTerm.value
    } else {
        queryDict.value['searchTerm'] = undefined
    }

    delete queryDict.value['pageNumber']

    if (metadata.value?.pageNumber && metadata.value?.pageNumber !== 1) {
        queryDict.value['pageNumber'] = metadata.value?.pageNumber?.toString()
    }

    props.tableFilters.forEach((filter) => {
        if (!filter.value || filter.hidden) {
            filter.value = ''
        }
        queryDict.value[filter.key] = filter.value.toString()
    })

    props.dateTableFilters.forEach((dateFilter) => {
        if (!dateFilter.value) {
            dateFilter.value = null
        }
        queryDict.value[dateFilter.key] = localDateTimeFilter(dateFilter.value, 'MMM D, YYYY')
    })

    if (!isEqual(route.query, queryDict.value)) {
        routeChanged.value += 1
        emit('routeChanged', queryDict.value)
        router.replace({ name: route.name!, query: queryDict.value })
    }
}

async function filterChanged(clearFilterKeyValue?: string) {
    emit('filterChanged')
    clearFilterKey(clearFilterKeyValue)
    updateQueryString()
}

// this needs to be private in comp api's version
function clearFilterKey(clearFilterKey?: string) {
    if (clearFilterKey) {
        const filterToClear = props.tableFilters.find((f) => f.key === clearFilterKey)
        if (filterToClear) {
            filterToClear.value = ''
        }
    }
}

// this needs to be private in comp api's version
function applyStickyStyling(el: HTMLElement, height: number) {
    el.classList.add('sticky')
    el.style.top = `${height}px`
}

function checkSearchMessages(messages: string[] | null) {
    warningMessage.value = messages != null && metadata.value?.totalCount != undefined && metadata.value.totalCount > 0 ? messages[0] : null
}

async function handlePagedResponse(params?: URLSearchParams) {
    // Set this to ensure we get the most recent query results. For example, if we immediately search after updating a filter, we will have two responses.
    // The latter may return first, meaning the former, stale query is returned and incorrectly populates the table.
    mostRecentParams.value = params
    loading.value = true
    const res = await props.getList(params)

    // Only update table using the latest query, ignoring any past ones still being processed by our api
    if (isEqual(mostRecentParams.value, params)) {
        let messages: string[] | null = null
        if (res.metadata != null) {
            metadata.value = metadata.value || ({} as PagingMetadata)
            metadata.value.pageNumber = res.metadata.pageNumber
            metadata.value.pageSize = res.metadata.pageSize
            metadata.value.totalCount = res.metadata.totalCount
            messages = res.metadata.messages
        }

        list.value = res.items
        loading.value = false
        checkSearchMessages(messages)
    }
}

async function onSearchInput() {
    updateQueryString()
}

async function onSortChanged(sortCtx: BvTableCtxObject) {
    if (metadata.value != null && sortCtx.sortBy) {
        metadata.value.sort = [`${sortCtx?.sortDesc ? '-' : ''}${sortCtx.sortBy}`]
        await userPreferenceService.setGridSortBy(props.gridId, JSON.stringify(sortCtx))

        if (props.serverSideSorting) await handlePagedResponse(generatePagedParams(metadata.value))
    }
}

async function goToPage(val: number) {
    if (metadata.value != null) {
        metadata.value.pageNumber = val
        updateQueryString()
    }
}

async function onPageSize(pageSize: number) {
    if (metadata.value != null) {
        metadata.value.pageSize = pageSize
        await userPreferenceService.setGridPageSize(props.gridId, pageSize)
        await handlePagedResponse(generatePagedParams(metadata.value))
    }
}

function formatData(fieldKey: string, data: any): string {
    let returnData = ''
    const field = filteredFields.value.find((field) => field.key === fieldKey)
    if (field && field.formatData) {
        returnData = field.formatData(data)
    }
    return returnData
}

function formatTitle(fieldKey: string, data: any): string {
    
    let returnData = ''
    const field = filteredFields.value.find((field) => field.key === fieldKey)
    if (field) {
        if (field.formatTitle) {
            returnData = field.formatTitle(data)
        } else {
            if (field.formatHtml) {
                returnData = field.formatHtml(data)
            }
            else {
                if (field.formatData) {
                    returnData = field.formatData(data)
                }
                if (field.formatSecondaryData) {
                    returnData += '<br>' + field.formatSecondaryData(data)
                }
            }
        }
    }
    return returnData
}

function formatSecondaryData(fieldKey: string, data: any): string {
    let returnData = ''
    const field = filteredFields.value.find((field) => field.key === fieldKey)
    if (field && field.formatSecondaryData) {
        returnData = field.formatSecondaryData(data)
    }
    return returnData
}

function formatHtml(fieldKey: string, data: any): string {
    let returnData = ''
    const field = filteredFields.value.find((field) => field.key === fieldKey)
    if (field && field.formatHtml) {
        returnData = field.formatHtml(data)
    }
    return returnData
}

//this needs to be private in comp api's version
function generatePagedParams(metadata: PagingMetadata | null): URLSearchParams {
    const params = new URLSearchParams()
    generatePagedParam(params, 'pageNumber', metadata?.pageNumber)
    generatePagedParam(params, 'pageSize', metadata?.pageSize)
    generatePagedParam(params, 'searchTerm', searchTerm.value)
    if (metadata?.filters && metadata.filters.length > 0) {
        metadata.filters.forEach((filter) => generatePagedParam(params, filter.key, filter.value))
    }
    if (metadata?.sort && metadata.sort.length > 0) {
        metadata.sort.forEach((s: string) => params.append('sort', s))
    }
    return params
}

//this needs to be private in comp api's version
function generatePagedParam(params: URLSearchParams, name: string, value: any) {
    if (value) {
        params.append(name, value.toString())
    }
}

function onCellClicked(item: any) {
    props.rowClicked(item)
}

function onRowClicked(item: any) {
    if (allowRowClick.value || !props.primaryKey) {
        props.rowClicked(item)
    }
}
</script>

<style scoped lang="scss">
.b-overlay-wrap {
    height: 5rem;
}

.filter-row {
    position: sticky;
    top: $header-height + 0.75rem;
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    gap: 0.75rem;

    // These ensure the grid rows are invisible when they scroll up behind the search area
    min-height: $header-height;
    background: $background-color;
    z-index: 2; // puts the headers behind the dropdowns

    .search-input {
        width: 16rem;
    }

    .date-picker {
        width: 9rem;
    }
}

.pagination-container {
    margin-left: auto;
}

:deep {
    thead {
        position: sticky;
        top: $header-height + 3.25rem;
        z-index: 1; // keeps the hover menu from overlaying the header
    }

    .table-hover.default-row-hover {
        tbody tr:hover {
            cursor: default;
        }
    }

    .table tbody tr.error-row {
        color: $red;
    }

    .col-action {
        .action-items {
            display: flex;
            justify-content: end;
            align-items: center;
        }

        button {
            margin-left: 0.5rem;
        }
    }
}

:deep(.sticky) {
    position: sticky !important;
}
</style>