<template>
    <div :id="id" class="checkbox-list">
        <label v-if="label">{{ label }}</label>
        <search-input id="stateSearch" v-model="searchText" :debounce-time="0" />
        <template v-if="items">
            <div class="links">
                <a href="#" @click="setSelected(true)">Select All</a>
                <a href="#" @click="setSelected(false)">Deselect All</a>
            </div>
            <ul class="list-unstyled item-list">
                <loading-overlay :show="loading" class="checkbox-list-items">
                    <slot name="prepend" />
                    <li v-for="(item, i) of filteredItems" :key="i" class="list-item" :id="`itemRow_${id}${i}`" @mouseover="onRowHovered(item)" @mouseleave="onRowUnhovered">
                        <div class="custom-control custom-checkbox checkbox-list-item">
                            <input type="checkbox" class="custom-control-input" :id="`item_${id}${i}`" v-model="item.selected" @change="updateSelectedValues" />
                            <label class="custom-control-label" :for="`item_${id}${i}`">{{ item.text }}</label>
                            <div v-if="item.details" class="details" :for="`item_${id}${i}`" @click="toggleCheckboxAndUpdateSelectedValues(item)">{{ item.details }}</div>
                            <div v-if="item.secondaryText" class="secondary" :for="`item_${id}${i}`" @click="toggleCheckboxAndUpdateSelectedValues(item)">{{ item.secondaryText }}</div>
                        </div>
                        <b-tooltip v-if="showRowTooltip" :target="`itemRow_${id}${i}`"  custom-class="tooltip-md" triggers="hover" :delay="tooltipDelay">
                            <div v-html="rowTooltip"></div>
                        </b-tooltip>
                    </li>
                    <slot name="append" />
                </loading-overlay>
            </ul>
        </template>
    </div>
</template>

<script setup lang="ts">
import { SelectableItem } from '@/common/components/multi-select-item'
import { PropType, computed, ref, defineProps, defineEmits } from 'vue'
//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'update:source', source: SelectableItem[])
    (e:'update:selectedValues', value: string[])
    (e:'update:selectedItems', value: SelectableItem[])
    (e: 'rowHovered', item: SelectableItem)
}>()

const props = defineProps({
    id: {type: String, required: true},
    label: {type: String},
    source: {type: Array as PropType<Array<SelectableItem>>, required: true},
    selectedValues: {type: Array as PropType<Array<string>>},
    selectedItems: {type: Array as PropType<Array<SelectableItem>>},
    loading: {type: Boolean},
    searchOn: {type: Array as PropType<Array<string>>}, //Selectable Item Property Names
    tooltipDelay: { type: Number, default: 750 },
    rowTooltip: {type: String, default: ''}
})

const searchText = ref("")
const rowHovered = ref(false)
//#endregion

//#region COMPUTED
const filteredItems = computed(() => items.value.filter(filterItems))

const items = computed({
    get: () => {
        return props.source
    },
    set: (source: SelectableItem[]) => {
        emit('update:source', source)
    }
})

const showRowTooltip = computed<boolean>(() => rowHovered.value && !!props.rowTooltip)
//#endregion

function getSelectedItems(): SelectableItem[] {
    return items.value.filter((x) => x.selected)
}

function getSelectedValues(): string[] {
    return getSelectedItems().map((x) => x.value)
}

function updateSelectedValues() {
    emit('update:selectedValues', getSelectedValues())
    emit('update:selectedItems', getSelectedItems())
}

function toggleCheckboxAndUpdateSelectedValues(item){
   const itemIndex = items.value.findIndex(i => i.value === item.value)
   if (itemIndex !== -1){
    items.value[itemIndex].selected = !items.value[itemIndex].selected
   }
    updateSelectedValues()
}

function filterItems(item: SelectableItem) {
    // Even if it was unable to match by value, we don't want to return false. We still should allow searching by item.text
    if (item.value && item.value.toLowerCase().indexOf(searchText.value.toLowerCase()) !== -1)
        return true

    if (props.searchOn && props.searchOn.length > 0){
        return props.searchOn.some(v => item[v]?.toLowerCase()?.indexOf(searchText.value.toLowerCase()) ?? -1 !== -1)
    }

    return item.text && item.text.toLowerCase().indexOf(searchText.value.toLowerCase()) !== -1
}

function setSelected(checked: boolean) {
    filteredItems.value.forEach((x) => (x.selected = checked))
    updateSelectedValues()
}

function onRowHovered(item: SelectableItem) {
    rowHovered.value = true
    emit('rowHovered', item)
}

function onRowUnhovered() {
    rowHovered.value = false
}

</script>

<style scoped lang="scss">
ul {
    border: 1px solid $input-border-color;
    margin-bottom: 0;

    &.item-list {
        flex: 1;
        overflow: hidden;
    }

    .b-overlay-wrap {
        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: baseline;
        margin: 0;
        padding: 0.25rem 0.375rem;
        font-size: unset;
        color: $body-color;

        &:hover {
            background: $highlight-color;
        }

        input {
            margin-right: 0.5rem;
        }
    }
}

.checkbox-list {
    height: 20rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &-items {
        overflow: hidden;
    }
    &-item {
        width: 100%;
        margin-left: 0.3rem !important;
    }

    .links {
        margin-top: 0.5rem;
    }
}
.list-item * {
    cursor: pointer;
}
.custom-control-label {
    width: 100%;
}

a {
    font-size: 0.75rem;
    margin-right: 0.75rem;
}

</style>