import axios from 'axios'
import { axiosBackendService, userService } from './index'
import { ClientError, LoanLockRequest, LoanRecord, LoanSummary, UpdateDocumentModeRequest } from '../models'

class BackendService {
    async downloadIntegrationXml(data?: any, integrationName?: string): Promise<any> {
        const response = await axiosBackendService.post('integrations/download-file', data)

        if(response.status === 200 && response.data?.succeeded) {
            try {
                return await axios.get(response.data.downloadUrl, { headers: {Accept: 'application/xml'}, responseType: 'text'})
            } catch {
                // In response to "random issues" when accessign Azure storage, implement 1 retry after a few seconds
                await new Promise(resolve => setTimeout(resolve, 3000));
                try {
                    return await axios.get(response.data.downloadUrl, { headers: {Accept: 'application/xml'}, responseType: 'text'})
                } catch (err) {
                    await this.throwAndLog(integrationName, err)
                }
            }
        } else {
            await this.throwAndLog(integrationName, `Unkown error occurred while downloading the XML data from ${integrationName}.`)
        }
    }

    private async throwAndLog(integrationName, err) {
        const user = await userService.getCurrentUserProfile()
        await backendService.logClientErrors(new Error(err), integrationName, user.name)

        throw `An error occurred while downloading the XML data from ${integrationName}. Please try again or contact support for assistance.`
    }

    // "converts" CD Loan by changing key data points, then running data through LoanCreator to overwrite the existing CD loan and create a new MOD loan
    async convertCDLoanToMod(loanId: string): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`/loans/converted-mod?loanId=${loanId}`)
        return response.data
    }

    async ping(id: string): Promise<LoanRecord> {
        const config = {
            headers: {'Content-Type': 'application/json'} // adding header to handle WAF error message for post with no content-type
        }
        const response = await axiosBackendService.post(`loans/${id}/ping`, undefined, config) //passing undefined to satisfy method parameters
        return response.data
    }

    async lock(id: string, request: LoanLockRequest): Promise<LoanRecord> {
        let url = `loans/${id}/lock/`
        url += request.lock ? 'on' : 'off'
        const config = {
            headers: {'Content-Type': 'application/json'}, // adding header to handle WAF error message for post with no content-type
            params: {
                'force': `${request.force ? 'true' : 'false'}` // if it gets passed as undefined otherwise would interpolate directly
            }
        }
        const response = await axiosBackendService.post(url, undefined, config) //passing undefined to satisfy method parameters
        return response.data
    }

    async getPersisted(id: string, returnCachedId: boolean, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord | null> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`,
                'returnCachedId': `${returnCachedId}`
            }
        }
        const response = await axiosBackendService.get(`loans/${id}/persisted`, config)
        return response.data
    }

    async commitCacheRecord(id: string): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`loans/${id}/commit`)
        return response.data
    }

    async patch(id: string, patchData: any, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`
            }
        }

        const response = await axiosBackendService.patch(`loans/${id}?`, patchData, config)
        return response.data
    }

    async getLatestComplianceResults(clientCode: string, loanNumber: string): Promise<Blob> {
        const url = `compliance/latest?clientCode=${clientCode}&loanNumber=${loanNumber}`
        const response = await axiosBackendService.get(url, { responseType: 'arraybuffer' })
        return new Blob([response.data], { type: 'application/json'})
    }

    async getLatestComplianceResultsSummary(clientCode: string, loanNumber: string) {
        const response = await axiosBackendService.get(`/compliance/latest/summary?clientCode=${clientCode}&loanNumber=${loanNumber}`)
        return response.data
    }

    async downloadLoansCsvFileForRegCheck(params?: URLSearchParams) {
        const response = await axiosBackendService.get('compliance/transactions/csv', {
            responseType: 'arraybuffer',
            params: params 
        });
        return new Blob([response.data], {type: "text/csv;charset=utf-8"});
    }

    async createNewLoanFromXml(clientCode: string, loanNumber: string, documentMode: string, xml: string, schema: string, overwrite = false, sanitize = false, originatingSystem: string): Promise<LoanSummary> {
        xml = xml.replace(/[ï»¿]/g, '')
        const config = {
            headers: {'Content-Type': 'application/xml'},
            params: {
                'clientCode': `${clientCode}`,
                'loanNumber': `${loanNumber}`,
                'mode': `${documentMode}`,
                'schema': `${schema}`,
                'overwrite': `${overwrite}`,
                'originatingSystem': `${originatingSystem}`,
                'sanitize': `${sanitize}`,
            }
        }
        const response = await axiosBackendService.post('/import/', xml, config)
        return response.data
    }

    async renameLoan(id: string, newLoanNumber: string){
        const response = await axiosBackendService.post(`loans/${id}/loan-number`, { loanNumber: newLoanNumber })
        return response.data;
    }

    async logClientErrors(error: Error, info: string, userName: string): Promise<string> { 
        const url = `/client-error`
        const errorInfo = {
            userName: userName,
            info: info,
            stack: error.stack
        } as ClientError
        const correlationId = Math.random().toString(16).substr(2, 6).toUpperCase()
        const config = {
            headers: {'x-correlation-id': correlationId}
        } 
               
        try { 
            await axiosBackendService.post(url, errorInfo, config);
            return correlationId
        } catch (ex: any) {
            console.error(`Error logging client`, ex)
            return ''
        }        
    }

    async updateDocumentMode(loanId: string, request: UpdateDocumentModeRequest): Promise<LoanRecord> {
        const response = await axiosBackendService.post(`loans/${loanId}/document-mode`, request)
        return response.data
    }

    async getLoanTemplate() {
        const response = await axiosBackendService.get('/loans/treeview/')
        return response.data.nodes
    }
}

export const backendService = new BackendService()