import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { config } from '@/config'
import { authService, messageService } from './index'

class AxiosService {

    axios: AxiosInstance

    constructor(baseUrl: string) {
        this.axios = axios.create({
            baseURL: baseUrl,
            headers: {
                "x-calling-client": config.app.regCheckOnly ? "regcheck-ui" : "propel-ui"
            }
        })
    }

    getUri(url: string): string {
        const base = this.axios.defaults.baseURL ?? ''
        return this.CleanUrlSlash(base, url)

    }

    get(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.axios.get(url, config)
    }

    post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.axios.post(url, data, config)
    }

    put(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        return this.axios.put(url, data, config)
    }

    delete(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this.axios.delete(url, config)
    }

    patch(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        return this.axios.patch(url, data, config)
    }

    public CleanUrlSlash(base: string, url: string): string {
        return (base.endsWith('/') ? base.slice(0, -1) : base)
        + (url.startsWith('/') ? url : '/' + url)
    }
}

class AuthenticatedAxiosService extends AxiosService {

    constructor(baseUrl: string) {
        super(baseUrl)

        this.axios.interceptors.request.use(
            async request => {
                const token = await authService.getAccessToken()
                request.headers['Authorization'] = `Bearer ${token}`
                return request
            },
            error => error
        )

        this.axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config

                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true

                    try {
                        await authService.forceRefreshAccessToken()
                    } catch (refreshError) {
                        console.error('error refreshing access token', refreshError)
                        authService.logout()
                        return Promise.reject(refreshError)
                    }

                    const token = await authService.getAccessToken()
                    originalRequest.headers['Authorization'] = `Bearer ${token}`
                    return this.axios(originalRequest)
                }
                await this.handleError(error)
            }
        )
    }

    async handleError(error: any) {
        const correlationId = error.response?.headers["x-correlation-id"]
        const httpDetails = this.getHttpErrorDetails(error)
        switch (error.response?.status) {
            case 403:
                messageService.showSystemError(
                    "You attempted an operation that you may not be authorized to perform.  Please log out and try again.",
                    "Authorization Error",
                    "HTTP status 403",
                    correlationId)
                error.handled = true
                break
            case this.is400Error(error.response?.status):
                // Bubble up all 400 errors that aren't specifically handled here, but are handled elsewhere
                return Promise.reject(error)
            case 500:
                messageService.showSystemError(
                    "Propel encountered an error while processing your request, and the error has been logged.  Please contact support for assistance.",
                    "System Error",
                    httpDetails ?? error,
                    correlationId)
                error.handled = true
                break
            default:
                messageService.showSystemError('Propel encountered an unexpected error while processing your request.', 'System Error', httpDetails ?? undefined)
                error.handled = true
        }
    }

    private getHttpErrorDetails(error: any): string | null {
        if (error.config) {
            const c = error.config
            return `HTTP ${c.method.toUpperCase()} ${this.CleanUrlSlash(c.baseURL, c.url)}\nResponse: ${error.response?.status}\n${error.response?.data}`
        }
        else {
            return null
        }
    }

    private is400Error(error: any): string | null {
        if (!error)
            return null
        
        return error.toString().startsWith('4') ? error : null;
    }
}

export const axiosAuthService = new AxiosService(config.api.auth.publicUrl)
export const axiosBackendService = new AuthenticatedAxiosService(config.app.api.baseUrl)
export const axiosLoanService = new AuthenticatedAxiosService(config.api.loans.publicUrl)
export const axiosAdminService = new AuthenticatedAxiosService(config.api.admindata.publicUrl)
export const axiosMarketDataService = new AuthenticatedAxiosService(config.api.marketdata.publicUrl)
export const axiosMetadataService = new AuthenticatedAxiosService(config.api.metadata.publicUrl)
export const axiosUserService = new AuthenticatedAxiosService(config.api.users.publicUrl)
export const axiosDocGenService = new AuthenticatedAxiosService(config.api.docgen.publicUrl)
export const axiosOrdersService = new AuthenticatedAxiosService(config.api.orders.publicUrl)
export const axiosDeliveryService = new AuthenticatedAxiosService(config.api.delivery.publicUrl)
export const axiosFilesService = new AuthenticatedAxiosService(config.api.files.publicUrl)
export const axiosDocuSignUserAuthService = new AuthenticatedAxiosService(config.api.docuSign.baseUrl)
export const axiosMcdService = new AuthenticatedAxiosService(config.api.mcd.publicUrl)
